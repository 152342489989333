<script>
import configService from "@/services/config";

import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import authService from "@/services/auth";

export default {
  name: "FileUpload",

  components: {
    vueDropzone,
  },

  props: {
    dropzoneOptions: {
      type: Object,
      default: () => {},
    },
    // If set, overrides acceptedFiles
    type: String,
    url: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      options: {
        url: this.url,
        autoProcessQueue: false,
        addRemoveLinks: true,
        autoQueue: false,
        headers: { Authorization: `Bearer ${authService.getToken()}` },
        maxFiles: null,
        maxFilesize: 3,
        dictDefaultMessage: "Arrastrar archivos para subir o hacer click",
        dictRemoveFile: "Eliminar archivo",
        dictFallbackMessage: "Su navegador no soporta arrastrar archivos",
        dictFallbackText:
          "Por favor use el formulario debajo para subir archivos",
        dictFileTooBig:
          "El archivo es muy grande ({{filesize}}MiB). Tamaño máximo: {{maxFilesize}}MiB.",
        dictInvalidFileType: "No puedes subir archivos de este tipo",
        dictResponseError: "Servidor respondió con código {{statusCode}}",
        dictCancelUpload: "Cancelar subida",
        dictUploadCanceled: "Se canceló la subida de imágenes",
        dictCancelUploadConfirmation:
          "¿Está seguro que quiere cancelar la subida?",
        dictMaxFilesExceeded: "No puedes subir mas archivos",
        ...this.dropzoneOptions,
      },
    };
  },

  watch: {
    type: {
      immediate: true,
      handler(type) {
        if (type === "image") {
          this.options.acceptedFiles = "image/*";
        } else if (type === "audio") {
          this.options.acceptedFiles = ".mp3";
        } else if (type === "video") {
          this.options.acceptedFiles =
            "video/mp4,video/x-flv,video/quicktime,video/x-msvideo,video/x-ms-wmv";
        } else if (type === "file") {
          this.options.acceptedFiles = "application/pdf";
        } else if (type === "csv") {
          this.options.acceptedFiles = "text/csv";
        } else if (type === "csvAndMedia") {
          this.options.acceptedFiles =
            "image/*,video/mp4,video/x-flv,video/quicktime,video/x-msvideo,video/x-ms-wmv,.mp3,text/csv";
        }

        if (this.$refs.uploader) {
          this.$refs.uploader.setOption(
            "acceptedFiles",
            this.options.acceptedFiles
          );
        }
      },
    },
    url(newURL) {
      if (this.$refs.uploader) {
        this.$refs.uploader.setOption("url", newURL);
        this.$refs.uploader.dropzone.options.url = newURL;
      }
      this.options.url = newURL;
    },
    files(newFiles) {
      this.addFiles(newFiles);
    },
  },

  mounted() {
    this.addFiles(this.files);
  },

  methods: {
    startUpload() {
      const { files = [] } = this.$refs.uploader.dropzone;
      const filesToQueue = files.filter((f) => !f.key);
      // enqueue here to prevent upload files already uploaded (with key)
      this.$refs.uploader.dropzone.enqueueFiles(filesToQueue);

      // hack to upload files after updating the URL option
      setTimeout(() => {
        this.$refs.uploader.processQueue();
      }, 0);
    },

    onQueueCompleted() {
      this.$emit("queue-complete");
    },

    onSuccess() {
      this.$emit("fileupload-success");
    },

    onError(file, message, xhr) {
      file && this.$refs.uploader.removeFile(file);

      if (message !== this.options.dictUploadCanceled) {
        this.$message.error(message);
      }
      this.$emit("fileupload-error", { file, message, xhr });
    },

    onFileAdded(file) {
      if (!file.key) {
        this.$emit("file-added", file);
      }
    },

    onFileDeleted(deletedFile) {
      this.$emit("file-removed", deletedFile);

      const { files = [] } = this.$refs.uploader.dropzone;
      !files.length && this.$emit("files-removed");
    },

    addFiles(files = []) {
      files.forEach((file) => {
        this.addFile(file);
      });
    },

    addFile(file) {
      const fileKey = file.key || file.value || file.path;
      const fileName = this.getFileName(fileKey);
      const url = `${configService.s3Url}/${fileKey}`;

      const { files = [] } = this.$refs.uploader.dropzone;
      const isAdded = files.some((f) => f.key === fileKey);

      const canAdd =
        !this.options.maxFiles || this.options.maxFiles > files.length;

      !isAdded &&
        canAdd &&
        this.$refs.uploader.manuallyAddFile(
          {
            size: file.size,
            name: fileName,
            type: file.mimeType,
            key: fileKey,
          },
          url
        );
    },

    getFileName(key) {
      if (!key) {
        return;
      }
      try {
        const name = key.split("/").slice(-1)[0];
        return name;
      } catch (error) {
        return;
      }
    },
  },
};
</script>

<template lang="pug">
  .file-upload-wrapper
    vue-dropzone#dropzone(
      ref="uploader"
      :options="options"
      :duplicateCheck="true"
      @vdropzone-error="onError"
      @vdropzone-success="onSuccess"
      @vdropzone-file-added="onFileAdded"
      @vdropzone-removed-file="onFileDeleted"
      @vdropzone-queue-complete="onQueueCompleted"
    )
</template>
