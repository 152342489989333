<script>
import patientSevice from "@/services/patient";
import helperService from "@/services/helper";
import conversationService from "@/services/conversation";

export default {
  name: "DashboardConversations",

  data() {
    return {
      isLoading: false,
      searchInput: "",
      patients: [],
      channels: {},
    };
  },

  created() {
    this.getPatients();
  },

  computed: {
    sortedPatients() {
      return this.patients.slice().sort((a, b) => {
        const unreadA = a.unreadCount || 0;
        const unreadB = b.unreadCount || 0;
        return unreadB - unreadA;
      });
    },

    filteredPatients() {
      if (this.searchInput) {
        const query = this.searchInput.toLowerCase();
        const keywords = query.split(" ");

        return this.sortedPatients.filter((p) => {
          const firstName = p.firstName.toLowerCase();
          const lastName = p.lastName.toLowerCase();

          return keywords.some(
            (keyword) =>
              firstName.includes(keyword) || lastName.includes(keyword)
          );
        });
      }

      return this.sortedPatients;
    },

    doctorId() {
      return this.$store.state.user._id;
    },
  },

  methods: {
    deleteChannel(patientId) {
      return conversationService
        .getClientPromise()
        .then((twilioClient) => {
          const uniqueName = `${this.doctorId}-${patientId}`;
          return twilioClient.getChannelByUniqueName(uniqueName);
        })
        .then((channel) => {
          return channel.delete();
        })
        .then(() => this.$router.go());
    },

    getPatients(query) {
      this.isLoading = true;
      patientSevice
        .get(query)
        .then((res) => {
          const patients = res.docs;
          this.patients = patients.filter(
            ({ lastActivityAt }) => lastActivityAt
          );

          return Promise.all(
            patients.map(async (patient, patientIndex) => {
              const uniqueName = `${this.doctorId}-${patient._id}`;
              await this.loadChannel({
                uniqueName,
                user: patient._id,
                patientIndex,
              });
            })
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    resetUnreadCount({ _id }) {
      setTimeout(() => {
        this.patients = this.patients.map((patient) => {
          if (patient._id === _id) {
            return {
              ...patient,
              unreadCount: 0,
            };
          } else {
            return patient;
          }
        });
      }, 500);
    },

    getPatientName(patient) {
      return helperService.getFullName(patient);
    },

    async loadChannel({ uniqueName, user }) {
      if (this.channels[uniqueName]) {
        return;
      }

      try {
        const twilioClient = await conversationService.getClientPromise();
        const channel = await twilioClient.getChannelByUniqueName(uniqueName);
        const lastConsumedMessageIndex = channel.lastConsumedMessageIndex;
        const messagesCount = await channel.getMessagesCount();
        let unreadCount = 0;

        if (lastConsumedMessageIndex === null && messagesCount) {
          unreadCount = messagesCount;
        } else {
          unreadCount = await channel.getUnconsumedMessagesCount();
        }

        this.patients = this.patients.map((p) => {
          if (p._id === user) {
            return {
              ...p,
              unreadCount,
            };
          }
          return p;
        });
      } catch (error) {
        // eslint-disable
      }
    },
  },
};
</script>

<template lang="pug">
  section#conversations
    header.headline
      .headline__title
        h1 Conversaciones
        hr

    article.row.row--chat
      .search-mobile
        el-input.search__input.search__input--sidebar(
          prefix-icon="el-icon-search"
          v-model="searchInput"
          placeholder="Buscar"
          clearable
        )

      .contacts-list-wrapper
        .search
          el-input.search__input.search__input--sidebar(
            prefix-icon="el-icon-search"
            v-model="searchInput"
            placeholder="Buscar"
            clearable
          )
        ul.contact-list
          li.list__item(v-for="patient in filteredPatients", :class="{ 'is-active': patient._id === $route.params.id }" v-if="!isLoading")
              router-link.list__link(:to="{ name: 'dashboard-conversation', params: { id: patient._id } }")
                .list__content(@click="resetUnreadCount(patient)")
                  img.list__avatar(src="/img/avatar.png")
                  .list__info.contact-item
                    strong.list__text {{ getPatientName(patient) }}
                    strong.unread-count(v-if="patient.unreadCount") {{ patient.unreadCount }}
                      //- span.tag.list__tag Paciente
                    //- small.list__message mensaje
                //- span.list__badge
                  strong 1

      //- .sidebar.box__sidebar
        .search.headline__search.sidebar__search
          el-input.search__input.search__input--sidebar(
            prefix-icon="el-icon-search"
            v-model="searchInput"
            placeholder="Buscar"
            clearable
          )
        nav.list
          li.list__item(v-for="patient in filteredPatients", :class="{ 'is-active': patient._id === $route.params.id }")
            router-link.list__link(:to="{ name: 'dashboard-conversation', params: { id: patient._id } }")
              .list__content
                img.list__avatar(src="/img/avatar.png")
                .list__info
                  strong.list__text {{ getPatientName(patient) }}
                    //- span.tag.list__tag Paciente
                  //- small.list__message mensaje
              span.list__badge(@click="deleteChannel(patient._id)")
                strong x
      .box.box--with-subnav.box--full.box--darker
        router-view
</template>

<style lang="scss">
#conversations {
  .row--chat {
    display: flex;
    flex-direction: row;
    height: 70vh;

    .list__content {
      width: 100%;
    }

    .contact-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .list__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .unread-count {
        margin-left: auto;
        background: $primary;
        border-radius: 100%;
        color: white;
        width: 20px;
        min-width: 20px;
        height: 20px;
        min-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }

  .search-mobile {
    display: none;
  }

  .box__sidebar {
    height: 100%;
    min-height: 100%;
  }

  .list__info {
    display: flex;
  }

  .contacts-list-wrapper {
    overflow-y: scroll;

    li {
      background-color: white;
      list-style-type: none;

      &.is-active {
        background-color: #f5faff;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .row--chat {
      flex-direction: column;

      .search-mobile {
        display: block;
        height: 50px;
        max-height: 50px;
        min-height: 50px;

        .search__input {
          height: 50px;
          max-height: 50px;
          min-height: 50px;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

          .el-input__inner {
            height: 50px;
            max-height: 50px;
            min-height: 50px;
          }
        }
      }

      .contacts-list-wrapper {
        display: flex;
        height: 60px;
        max-height: 60px;
        align-items: center;

        .contact-list {
          display: flex;
          overflow: auto;
          width: 100%;
        }

        .search {
          display: none;
        }
      }
    }
  }
}
</style>
