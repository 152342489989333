import api from "./api";

const appointmentService = {};
const endpoint = "/appointments";

appointmentService.createAppointment = function (appointment) {
  return api.post(endpoint, appointment).then(({ data }) => data);
};

appointmentService.getAppointmentById = function (id) {
  return api.get(`${endpoint}/${id}`).then(({ data }) => data);
};

appointmentService.deleteAppointment = function (id) {
  return api.delete(`${endpoint}/${id}`).then(({ data }) => data);
};

export default appointmentService;
