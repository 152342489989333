import VadiguAppointmentsView from "@/components/appointment/VadiguAppointmentsView";
import AppointmentsView from "@/components/appointment/AppointmentsView";
import AgendasView from "@/components/appointment/AgendasView";
import { theme } from "@/services/config";

const appointmentsEngine = {
  albya: VadiguAppointmentsView,
  default: AppointmentsView,
};

const appointmentRoutes = [
  {
    path: "/appointment",
    name: "appointment",
    component: appointmentsEngine[theme] || appointmentsEngine.default,
  },
  {
    path: "/appointment/agendas",
    name: "appointment-agendas",
    component: AgendasView,
  },
];

export default appointmentRoutes;
