<script>
/*
This whole file has a tricky workaround to handle UTC and GMT differences.
The main reason is that the calendar element ui is based in UTC, but when you get a value from it, it turns it into GMT.

For example: If you select a random date such as 15/07, the calendar element guess it's `2022-07-15T00:00:00.000Z` but when
you save the value in a prop it saves as `2022-07-14T21:00:00.000 GMT-0300`.

It's a reported bug, you can find the demos in their webpage bugging https://element.eleme.io/#/es/component/calendar
(Click on a date in Basic Calendar demo and see what happens)

I've marked the 'tricky' parts with a comment to identify faster those parts. Find the word 'workaround'.
*/
import moment from "moment";
import {
  format,
  addMilliseconds,
  startOfDay,
  isBefore,
  parseISO,
} from "date-fns";
import Spinner from "@/components/ui/Spinner";
import { mapGetters } from "vuex";
import userService from "@/services/user";
import helperService from "@/services/helper";
import ModalAppointment from "@/components/appointment/ModalAppointment.vue";
import SlotsCalendar from "@/components/appointment/SlotsCalendar.vue";
import institutionService from "@/services/institution";
import slotService from "@/services/slot";
import doctorService from "@/services/doctor";

import {
  SLOT_ATTENTION_TYPES,
  APPOINTMENTS_STATUS,
  VIDEOROOM_MM_OFFSET_PRE,
  VIDEOROOM_MM_OFFSET_POST,
} from "@/services/constants";

export default {
  name: "AppointmentsView",

  components: {
    Spinner,
    ModalAppointment,
    SlotsCalendar,
  },

  data() {
    return {
      doctors: [],
      institutions: [],
      medicalSpecialties: [],
      selectedDate: "",
      selectedDateSlots: [],
      selectedDoctorId: "",
      selectedInstitutionId: "",
      selectedMedicalSpecialtyId: "",
      selectedStatus: "",
      selectedSlot: {
        rangeHour: "",
        timeDuration: "",
        start: "",
        institutionId: "",
        medicalSpecialties: "",
      },
      isModalVisible: false,
      isLoadingInstitutions: false,
      isLoadingDoctors: false,
      isLoadingDateSlots: false,
      appointmentsStatus: APPOINTMENTS_STATUS,
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isDoctor", "user"]),

    filteredSlots() {
      if (this.selectedStatus === "free")
        return this.selectedDateSlots.filter((slot) => slot.available);
      else if (this.selectedStatus === "busy")
        return this.selectedDateSlots.filter((slot) => !slot.available);
      return this.selectedDateSlots;
    },
  },

  watch: {
    selectedInstitutionId() {
      this.selectedDateSlots = [];
      if (this.selectedInstitutionId) {
        this.getDoctorsByInstitution(this.selectedInstitutionId);
      } else {
        this.doctors = [];
        this.selectedDoctorId = "";
        this.medicalSpecialties = [];
        this.selectedMedicalSpecialtyId = "";
      }
    },

    selectedDoctorId() {
      this.selectedDateSlots = [];
      if (this.selectedDoctorId) {
        this.getMedicalSpecialtiesByDoctor(this.selectedDoctorId);
      } else {
        this.medicalSpecialties = [];
        this.selectedMedicalSpecialtyId = "";
      }
    },

    selectedMedicalSpecialtyId() {
      this.selectedDateSlots = [];
      this.getSlotsByDate();
    },

    selectedDate() {
      if (this.selectedDate) {
        this.getSlotsByDate();
      }
    },
  },

  created() {
    this.$bus.$on("selected-date", (selectedDate) => {
      if (!isBefore(parseISO(selectedDate), startOfDay(new Date()))) {
        this.selectedDate = selectedDate;
      } else {
        this.selectedDateSlots = [];
        this.selectedDate = "";
      }
    });

    this.$bus.$on("appointment-created", (slot, appointmentId) => {
      const index = this.selectedDateSlots.findIndex((sl) => {
        return sl._id === slot._id;
      });
      this.selectedDateSlots[index].available = false;
      this.selectedDateSlots[index].appointment = appointmentId;
    });

    this.$bus.$on("appointment-deleted", (slot) => {
      const index = this.selectedDateSlots.findIndex(
        (sl) => sl._id === slot._id
      );

      this.selectedDateSlots[index].available = true;
      delete this.selectedSlots[index].appointment;
    });

    if (this.isDoctor) {
      this.getDoctorDetail();
    }
    if (this.isAdmin) {
      this.getInstitutions();
    }

    this.repeatRequest = setInterval(() => {
      this.getSlotsByDate();
    }, 15000);
  },

  beforeDestroy() {
    clearInterval(this.repeatRequest);
  },

  methods: {
    async getDoctorDetail(doctorId) {
      if (this.isDoctor) {
        doctorId = this.$store.state.user._id;
      }

      const doctorDetails = await doctorService.getDoctorDetails(doctorId);
      const { doctor } = doctorDetails;

      this.doctors = [doctorDetails];
      this.selectedDoctorId = doctorDetails._id;
      this.institutions = doctor.institutions;
      this.medicalSpecialties = doctor.medicalSpecialties;
    },

    async getInstitutions() {
      this.isLoadingInstitutions = true;
      try {
        const institutions = await institutionService.getInstitutions();
        this.institutions = institutions;
      } finally {
        this.isLoadingInstitutions = false;
      }
    },

    async getDoctorsByInstitution(institutionId) {
      this.isLoadingDoctors = true;
      try {
        const doctors = await userService.getDoctorsByInstitution(
          institutionId
        );
        this.doctors = doctors;
      } finally {
        this.isLoadingDoctors = false;
      }
    },

    getMedicalSpecialtiesByDoctor(doctorId) {
      if (this.doctors) {
        const [doctor] = this.doctors.filter(
          (doctor) => doctor._id === doctorId
        );
        const medicalSpecialties = doctor.doctor.medicalSpecialties;
        this.medicalSpecialties = medicalSpecialties;
      }
    },

    async getSlotsByDate() {
      this.selectedDateSlots = [];

      if (
        this.selectedDate &&
        this.selectedDoctorId &&
        this.selectedInstitutionId &&
        this.selectedMedicalSpecialtyId
      ) {
        this.isLoadingSlots = true;
        try {
          const query = {
            startDate: this.getDateFirstHourOfDay(this.selectedDate),
            endDate: this.getDateLastHourOfDay(this.selectedDate),
            doctor: this.selectedDoctorId,
            medicalSpecialty: this.selectedMedicalSpecialtyId,
            institution: this.selectedInstitutionId,
            populate: [
              {
                path: "appointment",
                populate: {
                  path: "videoRoom",
                },
              },
              { path: "doctor" },
              { path: "medicalSpecialty" },
            ],
          };

          const fetchedSlots = await slotService.getSlotsByDate(query);
          this.selectedDateSlots = fetchedSlots;
        } finally {
          this.isLoadingSlots = false;
        }
      }
    },

    getAttentionTypeName(type) {
      const attentionType = SLOT_ATTENTION_TYPES.filter(
        (elem) => elem.value === type
      );
      return attentionType[0].name;
    },

    hasActiveRoom(slot) {
      if (slot.appointment?.videoRoom?.status === "in-progress") {
        return true;
      } else {
        return false;
      }
    },

    isVideoRoomEnabled(slot) {
      if (slot.appointment) {
        const now = new Date();

        const offsetStartsAt = new Date(slot.appointment.startsAt);
        const offsetEndsAt = new Date(slot.appointment.endsAt);

        offsetStartsAt.setMinutes(
          offsetStartsAt.getMinutes() - VIDEOROOM_MM_OFFSET_PRE
        );
        offsetEndsAt.setMinutes(
          offsetEndsAt.getMinutes() + VIDEOROOM_MM_OFFSET_POST
        );

        return (
          slot.appointment.attentionType === "virtual" &&
          offsetStartsAt <= now &&
          offsetEndsAt >= now
        );
      } else {
        return false;
      }
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    getDateFirstHourOfDay(date) {
      // workaround
      const offset = moment().utcOffset() * -1;
      const firstHour = moment(date)
        .add(offset, "minutes")
        .startOf("day")
        .utc()
        .format();

      return firstHour;
      // workaround
    },

    getDateLastHourOfDay(date) {
      // workaround
      const offset = moment().utcOffset() * -1;
      const lastHour = moment(date)
        .add(offset, "minutes")
        .endOf("day")
        .utc()
        .format();

      return lastHour;
      // workaround
    },

    getStartAndEndHours(startsAt, endsAt) {
      const parsedStartHour = format(new Date(startsAt), "HH:mm");

      let parsedEndHour = format(addMilliseconds(new Date(endsAt), 1), "HH:mm");

      // workaround
      if (parsedEndHour === "00:00") {
        parsedEndHour = "24:00";
      }
      // workaround

      return `${parsedStartHour} - ${parsedEndHour}`;
    },

    goToPatientVideoroom(patient) {
      this.$router.push({
        name: "patient-videoroom",
        params: { id: patient },
      });
    },

    showModal(slot) {
      this.isModalVisible = true;
      this.selectedSlot = slot;
    },

    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<template lang="pug">
  section.appointment
    header.headline
        .headline__title
          h1
            | Turnos
          hr

        .headline__actions
          el-select(
            v-model="selectedInstitutionId"
            placeholder="Institución"
            filterable
            clearable
            required
          )   
            el-option(
              v-for="institution in institutions"
              :key="institution._id"
              :label="institution.name"
              :value="institution._id"
            )

          el-select(
            v-if="this.isAdmin"
            v-model="selectedDoctorId"
            placeholder="Médico"
            filterable
            clearable
            :no-data-text="selectedInstitutionId ? 'No hay doctores para el hospital seleccionado' : 'Seleccione un hospital'"
            default-first-option
          )
            el-option(
              v-for="doctor in doctors"
              :key="doctor._id"
              :label="`${getFullName(doctor)}`"
              :value="doctor._id"
            )
          
          el-select(
            v-model="selectedMedicalSpecialtyId"
            placeholder="Especialidad"
            filterable
            clearable
            required
            :no-data-text="selectedDoctorId ? 'El doctor no tiene especialidades' : 'Seleccione un doctor'"
          )   
            el-option(
              v-for="medicalSpecialty in medicalSpecialties"
              :key="medicalSpecialty._id"
              :label="medicalSpecialty.name"
              :value="medicalSpecialty._id"
            )

          el-select(
            v-model="selectedStatus"
            placeholder="Todos los turnos"
            filterable
            clearable
            default-first-option
          )
            el-option(
              v-for="(item,key,index) in appointmentsStatus"
              :key="index"
              :label="item"
              :value="key"
            )
          button(@click='getSlotsByDate()')
            micon(name="refresh")
    .box
      section
        article.row
          nav.sidebar
            slots-calendar(
              :selectedDoctorId="selectedDoctorId" 
              :selectedInstitutionId="selectedInstitutionId",
              :selectedMedicalSpecialtyId="selectedMedicalSpecialtyId"
              )

          .box__content--stretch
            spinner(v-if="isLoadingDateSlots")
            h2.noAlerts(v-if="!selectedDate") Seleccionar una fecha
            div(v-if="selectedDate")
              div(v-if="selectedInstitutionId")
                div(v-if="selectedDoctorId")
                  h2.noAlerts(v-if='!selectedMedicalSpecialtyId') Seleccionar una especialidad médica
                div(v-else)
                  h2.noAlerts Seleccionar un doctor
              div(v-else)
                h2.noAlerts Seleccionar una institución
              h2.no-alerts(v-if="selectedDate && selectedMedicalSpecialtyId && selectedInstitutionId && !isLoadingDateSlots && !selectedDateSlots.length") No se encontraron turnos
            .box.box--with-subnav
              .box__content--stretch
                table(v-if="selectedDate && !isLoadingDateSlots && selectedDateSlots.length")
                  thead
                    tr
                      th Doctor
                      th Especialidad
                      th Tipo de turno
                      th Fecha
                      th Horario
                      th Estado
                  tbody
                    tr(
                      v-for='slot in filteredSlots' :key="slot._id" @click="showModal(slot)" :class="slot.available ? 'free' : 'busy'")
                      td {{ getFullName(slot.doctor) }}
                      td {{ slot.medicalSpecialty.name }}
                      td 
                        .badge.attention(
                          v-for="type in slot.attentionType",
                          :key="type"
                        ) {{ getAttentionTypeName(type) }}
                      td {{ slot.startsAt | formatDate}}
                      td {{ getStartAndEndHours(slot.startsAt, slot.endsAt)}}
                      td
                        .badge.active-room(v-if='hasActiveRoom(slot)' @click="goToPatientVideoroom(slot.appointment.patient)") Paciente conectado
                        .badge.available-room(v-else-if='isVideoRoomEnabled(slot)') Sala habilitada
                        .badge(v-else :class="slot.available ? 'free' : 'busy'")
                          | {{ slot.available ? 'Disponible' : 'Ocupado' }}

        modal-appointment(
          v-if='this.isModalVisible'
          :show-dialog-modal='this.isModalVisible'
          :selected-slot='selectedSlot'
          @close="closeModal"
        )
</template>

<style lang="scss" scoped>
tbody .free:hover {
  cursor: pointer;
}

.box--with-subnav {
  box-shadow: none;
}

h2 {
  margin-top: 20px;
  text-align: center;
}

td {
  .badge {
    margin-right: 5px;
    padding: 5px;
    border-radius: 3px;
    display: inline-block;
  }

  .busy {
    background-color: #fef0f0;
    border: 1px solid #fde2e2;
    color: #f56c6c;
  }

  .free {
    background-color: #f0f9eb;
    border: 1px solid #e1f3d8;
    color: #67c23a;
  }
}

.attention {
  background-color: white;
  border: 1px solid $primary;
  color: $primary;
}

.empty-row {
  td {
    border: none;
  }
}

.active-room {
  background-color: green;
  color: $white;
  cursor: pointer;
}

.available-room {
  background-color: $primary;
  color: $white;
}

.row {
  display: grid;
  grid-template-columns: 40% 60%;
}
</style>
