<script>
import helperService from "@/services/helper";
import patientService from "@/services/patient";
import appointmentService from "@/services/appointment";
import {
  APPOINTMENTS_TYPES,
  VIDEOROOM_MM_OFFSET_PRE,
  VIDEOROOM_MM_OFFSET_POST,
} from "@/services/constants";
import { format, addMilliseconds } from "date-fns";
import pagination from "@/mixins/pagination";
import Spinner from "@/components/ui/Spinner";
import debounce from "debounce";
import institutionService from "@/services/institution";
import { isVideoRoomEnabled } from "@/services/config";

const DEBOUNCE_MS_TIMEOUT = 500;

export default {
  name: "ModalAppointment",

  mixins: [pagination],

  components: {
    Spinner,
  },

  props: {
    showDialogModal: {
      type: Boolean,
      default: false,
    },
    selectedSlot: {
      type: Object,
    },
  },

  data() {
    return {
      appointmentTypes: APPOINTMENTS_TYPES,
      attentionTypes: [],
      patients: [],
      searchInput: "",
      selectedPatient: null,
      appointment: {
        slot: this.selectedSlot._id,
        patient: null,
        description: "",
        status: "",
        attentionType: "",
        videoRoom: null,
      },
      institution: null,
      isNewAppointment: this.selectedSlot.available,
      isPosting: false,
      isDeleting: false,
      isLoading: false,
    };
  },

  computed: {
    isVideoRoomAvailable() {
      const now = new Date();

      const offsetStartsAt = new Date(this.appointment.startsAt);
      const offsetEndsAt = new Date(this.appointment.endsAt);

      offsetStartsAt.setMinutes(
        offsetStartsAt.getMinutes() - VIDEOROOM_MM_OFFSET_PRE
      );
      offsetEndsAt.setMinutes(
        offsetEndsAt.getMinutes() + VIDEOROOM_MM_OFFSET_POST
      );

      return (
        this.appointment.attentionType === "virtual" &&
        offsetStartsAt <= now &&
        offsetEndsAt >= now &&
        isVideoRoomEnabled
      );
    },
  },

  created() {
    if (this.isNewAppointment) {
      this.getPatients();
      this.appointment.status = "pending";
    } else {
      if (this.selectedSlot.appointment) {
        this.getAppointment();
      }
    }
    this.getInstitution();
    this.setAttentionTypes();
  },

  methods: {
    async getPatients(query = {}) {
      this.isLoading = true;

      const defaultQuery = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        ...query,
      };

      patientService
        .get(defaultQuery)
        .then((res) => {
          this.patients = res.docs;
          this.pagination.total = res.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    debounceInput: debounce(function () {
      const query = {
        offset: 0,
        limit: this.pagination.limit,
      };

      const input = this.searchInput && this.searchInput.split(" ");
      const useSearchIndex = input && (input.length === 1 || input.length > 3);

      // Search up to 3 words (Double names or double last names)
      const useNameIndex = input && input.length > 1 && input.length <= 3;

      if (useSearchIndex) {
        query.$text = {
          $search: this.searchInput,
        };
      } else if (useNameIndex) {
        Object.assign(query, helperService.createNameQuery(this.searchInput));
      }

      this.getPatients(query);
    }, DEBOUNCE_MS_TIMEOUT),

    async getAppointment() {
      this.appointment = await appointmentService.getAppointmentById(
        this.selectedSlot.appointment._id
      );
      await this.getPatientById();
    },

    async getInstitution() {
      this.institution = await institutionService.getInstitutionById(
        this.selectedSlot.institution
      );
    },

    async getPatientById() {
      const patient = await patientService.getById(this.appointment.patient);
      this.selectedPatient = patient;
    },

    setAttentionTypes() {
      this.selectedSlot.attentionType.forEach((type) => {
        this.attentionTypes.push(APPOINTMENTS_TYPES[type]);
      });
    },

    selectPatient(patient) {
      this.selectedPatient = patient;
      this.appointment.patient = patient._id;
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    getClassName(patient) {
      if (this.selectedPatient?._id === patient._id) {
        return "is-active";
      } else {
        return "";
      }
    },

    async createAppointment() {
      this.isPosting = true;
      try {
        const appointment = await appointmentService.createAppointment(
          this.appointment
        );
        this.$bus.$emit(
          "appointment-created",
          this.selectedSlot,
          appointment._id
        );
      } catch (error) {
        console.log(error);
      }

      this.isPosting = false;
      this.close();
    },

    async cancelAppointment() {
      this.$confirm(`¿Desea cancelar el turno?`, "Cancelar", {
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.isDeleting = true;
          appointmentService.deleteAppointment(this.appointment._id);
          this.$bus.$emit("appointment-deleted", this.selectedSlot);
          this.isDeleting = false;
          this.close();
        })
        .catch(() => {});
    },

    getStartAndEndHours(startsAt, endsAt) {
      const parsedStartHour = format(new Date(startsAt), "HH:mm");

      let parsedEndHour = format(addMilliseconds(new Date(endsAt), 1), "HH:mm");

      // workaround
      if (parsedEndHour === "00:00") {
        parsedEndHour = "24:00";
      }
      // workaround

      return `${parsedStartHour} - ${parsedEndHour}`;
    },

    async goToPatientVideoRoom() {
      this.$router.push({
        name: "patient-videoroom",
        params: { id: this.appointment.patient },
      });
    },

    submit() {
      this.createAppointment();
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>

<template lang="pug">
  el-dialog(:visible.sync="showDialogModal", :before-close="close")
      ValidationObserver(v-slot="{handleSubmit}")
        form.modal(@submit.prevent="handleSubmit(submit)")
          header.modal__header(v-if='isNewAppointment')
            h2.modal__title Agendar nuevo turno
            .modal__actions
              el-button(type="info" @click="close()" :disabled='isPosting') Cancelar
              el-button.border(type="primary" native-type="submit" :loading="isPosting") Agendar
          header.modal__header(v-else)
            h2.modal__title Turno asignado
            .modal__actions
              el-button.border(type="primary" @click="close()" :disabled='isPosting') Cerrar
          .modal__content
            .modal__block
              .modal__section
                .modal__sign.sign
                  .sign__icon
                    micon(name="calendar")
                  h3.sign__title Datos del turno
                article.modal__fields
                  .modal__row
                    fieldset.modal__field
                      label.label Fecha
                      p {{ selectedSlot.startsAt | formatDate}}
                    fieldset.modal__field
                      label.label Horario
                      p {{ getStartAndEndHours(selectedSlot.startsAt, selectedSlot.endsAt)}}
                    fieldset.modal__field
                      el-button.room-button(v-if='isVideoRoomAvailable' type="info" :loading="isPosting" @click='goToPatientVideoRoom') Ingresar a la sala
                  .modal__row    
                    fieldset.modal__field
                      label.label Doctor
                      p {{ getFullName(selectedSlot.doctor) }}
                    fieldset.modal__field
                      label.label Especialidad
                      p {{ selectedSlot.medicalSpecialty.name }}
                    fieldset.modal__field
                      label.label Lugar 
                      p {{ institution.name }} - {{ institution.address.street }} {{institution.address.buildingNumber}}, {{ institution.address.district }} {{institution.address.postalCode }}, {{ institution.address.district }}, {{ institution.address.city }} {{ institution.address.apartmentFloor ? `, Piso ${institution.address.apartmentFloor}` : "" }} {{ institution.address.apartmentNumber ? `, Número ${institution.address.apartmentNumber}` : "" }}  
                  .modal__row
                    fieldset.modal__field(v-if='isNewAppointment')
                        label.label * Modalidad
                        ValidationProvider(name="Modalidad", rules="required", v-slot="{ errors }")
                          el-select(
                            v-model="appointment.attentionType",
                            placeholder='Seleccione el tipo de turno'
                            required
                          )
                            el-option(
                              v-for="type in attentionTypes",
                              :key="type.code",
                              :label="type.friendlyName",
                              :value="type.value"
                            )  
                          span.has-error {{ errors[0] }} 
                    fieldset.modal__field(v-else)
                        label.label Modalidad 
                        p(v-if='appointment.attentionType') {{ appointmentTypes[appointment.attentionType].friendlyName }}  
                  div(v-if='selectedSlot.available || !selectedSlot.available && selectedSlot.appointment')  
                    .modal__row
                      fieldset.modal__field
                        label.label * Paciente
                        ValidationProvider(name="Paciente", rules="required", v-slot="{ errors }")
                          el-input(
                            :value="getFullName(selectedPatient)"
                            placeholder="Seleccione un paciente"
                            readOnly
                          )
                          span.has-error {{ errors[0] }} 

                    .box.box_table(v-if='isNewAppointment' :class='selectedPatient ? "selected" : ""')
                      el-input(
                        prefix-icon="el-icon-search"
                        v-on:input="debounceInput"
                        v-model="searchInput"
                        placeholder="Buscar"
                        :clearable="true"
                      )
                      .box__content--stretch
                        table
                          thead
                            tr
                              th Paciente
                              th Documento
                              th Número de Socio

                          tbody
                            spinner(v-if="isLoading")
                            tr(v-else v-for="(patient, index) in patients" :key="patient._id" :class="getClassName(patient)" @click="selectPatient(patient)")
                              td
                                b {{ getFullName(patient) }}
                              td {{ patient.governmentId && patient.governmentId.number }}
                              td {{ patient.patient.medicalInsurance ? patient.patient.medicalInsurance.number : 'N/A'}}

                      pagination(
                        :isLoading="isLoading"
                        :limit="pagination.limit"
                        :total="pagination.total"
                        @pagination="setPagination"
                      )      

                    .modal__row.patientData(v-if='selectedPatient')
                      fieldset.modal__field
                        label.label Mail
                        p {{ selectedPatient.email }}
                      fieldset.modal__field
                        label.label Teléfono
                        p {{ selectedPatient.phone }}

                    .modal__row.cancel-button(v-if='!isNewAppointment')
                      fieldset.modal__field
                        el-button(type="info" :loading="isPosting" @click='cancelAppointment') Cancelar turno

                  div(v-else)
                    h2.notAvailable El turno no se encuentra disponible

</template>

<style lang="scss" scoped>
.label {
  position: relative;
  .search-other {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.selected {
  height: auto;
}

.box_table {
  margin: 1rem 0.8rem 0;
}

.cancel-button {
  margin-top: 2rem;

  button {
    border: none;
    background-color: #c0392b;
  }

  button:hover {
    background-color: #892a1f;
  }
}

.room-button {
  border: none;
  background-color: rgb(113, 215, 135);
}

.is-active {
  background-color: $primary;
  color: $white;
}

.is-active:hover,
.is-active:hover td {
  background-color: $primary !important;
}

.patientData {
  margin-top: 1.5rem;
}

.notAvailable {
  text-align: center;
  margin-top: 1.5rem;
}
</style>
