import api from "./api";

const slotService = {};
const endpoint = "/slots";

slotService.getSlotsByDate = function (params) {
  return api.get(endpoint, { params }).then((res) => res.data);
};

slotService.getSlotsAvailability = function (params) {
  return api
    .get(`${endpoint}/availability`, { params })
    .then((res) => res.data);
};

export default slotService;
