import Vue from "vue";
import {
  format,
  formatDistanceStrict,
  parseISO,
  differenceInYears,
  differenceInMonths,
  differenceInDays,
} from "date-fns";
import dateFnsEsp from "date-fns/locale/es";

Vue.filter("formatDateAndTime", function (value) {
  return value ? format(parseISO(value), "dd/MM/yyyy HH:mm") : "";
});

Vue.filter("formatDate", function (value) {
  return value ? format(parseISO(value), "dd/MM/yyyy") : "Indefinido";
});

Vue.filter("time", function (value) {
  return value ? format(parseISO(value), "HH:mm") : "";
});

Vue.filter("age", function (value) {
  const isoString = new Date(value).toISOString();
  let difference = differenceInYears(new Date(), parseISO(isoString));
  if (difference) {
    return `${difference} año${difference > 1 ? "s" : ""}`;
  }

  difference = differenceInMonths(new Date(), parseISO(isoString));
  if (difference) {
    return `${difference} mes${difference > 1 ? "es" : ""}`;
  }

  difference = differenceInDays(new Date(), parseISO(isoString));
  if (difference) {
    return `${difference} día${difference > 1 ? "s" : ""}`;
  }
});

Vue.filter("formatDistanceStrict", function (value) {
  return value
    ? formatDistanceStrict(new Date(), parseISO(value), {
        addSuffix: false,
        locale: dateFnsEsp,
      })
        .replace("segundos", "segs")
        .replace("minuto", "min")
        .replace("horas", "hrs")
    : "";
});

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("formatSize", function (size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + " MB";
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + " KB";
  }
  return size.toString() + " B";
});
