<script>
import Spinner from "@/components/ui/Spinner";
import { mapGetters } from "vuex";
import { APPOINTMENTS_STATUS } from "@/services/constants";
import vadiguAppointmentService from "@/services/vadiguAppointments";
import userService from "@/services/user";
import helperService from "@/services/helper";
import doctorService from "@/services/doctor";
import VadiguModalAppointment from "@/components/appointment/VadiguModalAppointment.vue";

export default {
  name: "VadiguAppointmentsView",

  components: {
    Spinner,
    VadiguModalAppointment,
  },

  data() {
    return {
      doctors: [],
      medicalSpecialties: [],
      appointments: [],
      appointmentsStatus: APPOINTMENTS_STATUS,
      selectedStatus: "",
      selectedDate: new Date(),
      selectedDoctorId: "",
      selectedMedicalSpecialtyId: "",
      selectedAppointment: {
        rangeHour: "",
        timeDuration: "",
        start: "",
        institutionId: "",
        medicalSpecialties: "",
      },
      isNewAppointment: false,
      showDialogModal: false,
      isLoading: false,
      isWaitingRoomLoading: false,
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isDoctor", "user"]),

    filteredAppointments() {
      if (this.selectedStatus === "free")
        return this.appointments.filter(
          (appointment) => appointment.resource.status === "free"
        );
      else if (this.selectedStatus === "busy")
        return this.appointments.filter(
          (appointment) => appointment.resource.status !== "free"
        );
      return this.appointments;
    },
  },

  watch: {
    selectedDoctorId(selectedDoctorId) {
      if (!this.isDoctor) {
        if (!selectedDoctorId) {
          this.medicalSpecialties = [];
          this.selectedMedicalSpecialtyId = "";
          this.appointments = [];
        } else {
          this.medicalSpecialties = [];
          this.selectedMedicalSpecialtyId = "";
          this.appointments = [];
          const doctorIndex = this.doctors.findIndex(
            (doctor) => doctor._id === selectedDoctorId
          );
          this.medicalSpecialties =
            this.doctors[doctorIndex].doctor?.medicalSpecialties;
        }
      }
    },
    selectedMedicalSpecialtyId() {
      if (!this.selectedMedicalSpecialtyId) {
        this.appointments = [];
      } else {
        if (this.selectedDate && this.selectedDoctorId) {
          this.getAppointments();
        }
      }
    },
    selectedDate() {
      if (
        this.selectedDate &&
        this.selectedDoctorId &&
        this.selectedMedicalSpecialtyId
      ) {
        this.getAppointments();
      }
    },
    showDialogModal(newValue) {
      if (!newValue) {
        this.getAppointments();
      }
    },
  },

  created() {
    if (this.isDoctor) {
      this.selectedDoctorId = this.$store.state.user._id;
      this.getMedicalSpecialtiesByDoctor();
    } else {
      this.getDoctors();
    }
  },

  methods: {
    async getMedicalSpecialtiesByDoctor() {
      const doctorDetails = await doctorService.getDoctorDetails(
        this.selectedDoctorId
      );

      this.medicalSpecialties = doctorDetails.doctor?.medicalSpecialties;
    },

    async getDoctors() {
      this.isLoading = true;
      const { docs } = await userService.getDoctors({
        populate: "doctor.medicalSpecialties",
      });
      this.doctors = docs;
      this.isLoading = false;
    },

    async getAppointments() {
      this.isLoading = true;
      this.appointments = [];

      try {
        const { parseDayBegin, parseDayEnd } =
          vadiguAppointmentService.formatDateSelected(this.selectedDate);

        const response = await vadiguAppointmentService.getAppointments({
          doctorId: this.selectedDoctorId,
          medicalSpecialtyId: this.selectedMedicalSpecialtyId,
          start: parseDayBegin,
          end: parseDayEnd,
        });

        // Vadigu will send institutionId inside each appointment and this will be not necessary
        const appoinmentsWithInstitutionId = [];

        response.forEach((appointments) => {
          const data = appointments.value.results;
          const institutionId = appointments.value.institutionId;
          data.forEach((appointment) => {
            appoinmentsWithInstitutionId.push({
              ...appointment,
              institutionId,
            });
          });
        });
        this.appointments = appoinmentsWithInstitutionId;
      } catch (error) {
        console.log("Error", error);
      }

      this.isLoading = false;
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    formatTimeAppointment(start, end) {
      return `${start.slice(11, 16)} HS - ${end.slice(11, 16)} HS`;
    },

    showModal(appointment) {
      this.isNewAppointment = appointment.resource.status !== "busy";
      const dateStart = new Date(appointment.resource.start);

      if (dateStart > Date.now() && this.isNewAppointment && !this.isAdmin) {
        this.selectedAppointment.rangeHour = this.formatTimeAppointment(
          appointment.resource.start,
          appointment.resource.end
        );
        this.selectedAppointment.timeDuration = this.calculateDurationTime(
          appointment.resource.start,
          appointment.resource.end
        );
        this.selectedAppointment.start = appointment.resource.start;
        this.selectedAppointment.institutionId = appointment.institutionId;
        this.selectedAppointment.medicalSpecialties =
          appointment.resource.specialty[0].coding[0].code;
        this.showDialogModal = true;
      }
    },

    calculateDurationTime(start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const diffTime = endDate.getTime() - startDate.getTime();
      const diffMinutes = Math.ceil(diffTime / (1000 * 60));
      return `${diffMinutes}`;
    },

    closeModal() {
      this.showDialogModal = false;
    },

    async goToWaitingRoomUrl() {
      this.isWaitingRoomLoading = true;
      const url = await vadiguAppointmentService.getWaitingRoomUrl();
      window.open(url, "_blank");
      this.isWaitingRoomLoading = false;
    },
  },
};
</script>

<template lang="pug">
  section.appointment
    header.headline
        .headline__title
          h1
            | Turnos
          hr
        .headline__actions

          el-select(
              v-if="appointments"
              v-model="selectedStatus"
              placeholder="Todos los turnos"
              filterable
              clearable
              default-first-option
            )
              el-option(
                v-for="(item,key,index) in appointmentsStatus"
                :key="index"
                :label="item"
                :value="key"
              )  

          el-select.doctor-assigned(
            v-if="this.isAdmin"
            v-model="selectedDoctorId"
            placeholder="Seleccione un doctor"
            filterable
            clearable
            default-first-option
          )
            el-option(
              v-for="doctor in doctors"
              :key="doctor._id"
              :label="`${getFullName(doctor)}`"
              :value="doctor._id"
            )

          el-select.doctor-assigned(
            v-model="selectedMedicalSpecialtyId"
            placeholder="Seleccione una especialidad"
            filterable
            clearable
            default-first-option
            :no-data-text="selectedDoctorId ? 'El doctor no tiene especialidades' : 'Seleccione un doctor'"
          )
            el-option(
              v-for="medicalSpecialty in medicalSpecialties"
              :key="medicalSpecialty._id"
              :label="medicalSpecialty.name"
              :value="medicalSpecialty._id"
            )

          el-date-picker(v-model='selectedDate' placeholder="Selecciona una fecha", format='dd-MM-yyyy' name="date")

          button.button.button--blue(@click="goToWaitingRoomUrl" :disabled="isWaitingRoomLoading")
            micon.button__icon(name="smartphone")
            span.button__text {{!isWaitingRoomLoading ? "Ir a sala de espera" : "Cargando..."}}

    .box
      .box__content--stretch(v-if='selectedDoctorId && selectedMedicalSpecialtyId && selectedDate')
        spinner(v-if="isLoading")
        h2.no-alerts(v-if="!isLoading && !appointments.length ") No se encontraron turnos
        table(v-if="!isLoading && appointments.length")
          thead
            tr
              th Paciente
              th Especialidad
              th Tipo de turno
              th Fecha
              th Horario
              th Duración
              th Estado
              //- th Acciones
          tbody
            tr(
              v-for='(appointment,index) in filteredAppointments' :key="index" :class='(appointment.resource.status === "free" && isDoctor ? "free" : "busy") ' @click="showModal(appointment)")
              td  (Paciente)
              td  {{ appointment.resource.specialty[0].coding[0].display }}
              td  (Virtual/Presencial)
              td {{ appointment.resource.start | formatDate}}
              td  {{ formatTimeAppointment(appointment.resource.start,appointment.resource.end) }}
              td {{ `${calculateDurationTime(appointment.resource.start,appointment.resource.end)} minutos`}}
              td.busy(v-if='appointment.resource.status !== "free"')
                p Ocupado
              td.available(v-else)
                p Disponible  
              
      .box__content--stretch(v-else)
          h2(v-if='!selectedDoctorId') Seleccione un doctor...
          h2(v-else-if='selectedDoctorId && !selectedMedicalSpecialtyId') Seleccione una especialidad...
          h2(v-else) Seleccione una fecha...


    vadigu-modal-appointment(
      v-if='this.showDialogModal'
      custom-class="el-dialog__body",
      :is-new-appointment='isNewAppointment',
      :doctorId='selectedDoctorId',
      :appointment='selectedAppointment'
      :selected-date='selectedDate'
      :show-dialog-modal="showDialogModal",
      @close="closeModal"
    )


      //- AGREGAR COLUMNAS
            TIPO DE TURNO (PRESENCIAL O VIRTUAL)
            ESTADO (ADMISIONADO, PENDIENTE, EN ATENCIÓN, FINALIZADO - COMENTARLO)

</template>

<style lang="scss" scoped>
@import "@/styles/settings/index.scss";
@import "@/styles/tools/index.scss";

tbody .free:hover {
  cursor: pointer;
}

h2 {
  margin-top: 20px;
  text-align: center;
}

.busy,
.available {
  margin-right: 10px;
  padding: 0 5px;
}

.busy {
  p {
    padding: 5px;
    border-radius: 3px;
    display: inline-block;
    background-color: #fef0f0;
    border-color: #fde2e2;
    border: 1px solid #fde2e2;
    color: #f56c6c;
  }
}

.available {
  p {
    padding: 5px;
    border-radius: 3px;
    display: inline-block;
    background-color: #f0f9eb;
    border-color: #e1f3d8;
    border: 1px solid #e1f3d8;
    color: #67c23a;
  }
}

.empty-row {
  td {
    border: none;
  }
}
</style>
