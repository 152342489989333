import { render, staticRenderFns } from "./DashboardUnrepliedConversations.vue?vue&type=template&id=2d0c2602&scoped=true&lang=pug&"
import script from "./DashboardUnrepliedConversations.vue?vue&type=script&lang=js&"
export * from "./DashboardUnrepliedConversations.vue?vue&type=script&lang=js&"
import style0 from "./DashboardUnrepliedConversations.vue?vue&type=style&index=0&id=2d0c2602&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d0c2602",
  null
  
)

export default component.exports