import Vue from "vue";
import { Button } from "element-ui";
import { Input } from "element-ui";
import { Select } from "element-ui";
import { Option } from "element-ui";
import { Checkbox } from "element-ui";
import { CheckboxGroup } from "element-ui";
import { Dropdown } from "element-ui";
import { DropdownMenu } from "element-ui";
import { DropdownItem } from "element-ui";
import { Calendar } from "element-ui";
import { Timeline } from "element-ui";
import { TimelineItem } from "element-ui";
import { Slider } from "element-ui";
import { Switch } from "element-ui";
import { Tag } from "element-ui";
import { Radio } from "element-ui";
import { Dialog } from "element-ui";
import { MessageBox } from "element-ui";
import { Message } from "element-ui";
import { DatePicker } from "element-ui";
import { RadioGroup } from "element-ui";
import { TimeSelect } from "element-ui";

import lang from "element-ui/lib/locale/lang/es";
import locale from "element-ui/lib/locale";

locale.use(lang);

Vue.use(DatePicker);
Vue.use(Button);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Calendar);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Slider);
Vue.use(Switch);
Vue.use(Tag);
Vue.use(Radio);
Vue.use(Dialog);
Vue.use(RadioGroup);
Vue.use(TimeSelect);

Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
