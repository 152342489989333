import api from "./api";

const agendaService = {};
const endpoint = () => `/agendas`;

agendaService.createAgenda = function (agenda) {
  return api.post(endpoint(), agenda).then(({ data }) => data);
};

agendaService.updateAgenda = function (agenda) {
  return api
    .put(`${endpoint()}/${agenda._id}`, agenda)
    .then(({ data }) => data);
};

agendaService.getAgendas = function (query) {
  return api
    .get(endpoint(), {
      params: {
        ...query,
        sort: { updatedAt: -1 },
        populate: [
          {
            path: "doctor",
            select: "firstName lastName _id",
          },
          {
            path: "institution",
            select: "name _id",
          },
          {
            path: "medicalSpecialty",
            select: "name _id",
          },
        ],
      },
    })
    .then((res) => res.data);
};

agendaService.lock = function (id) {
  return api.put(`${endpoint()}/${id}/lock`);
};

agendaService.unlock = function (id) {
  return api.put(`${endpoint()}/${id}/unlock`);
};

export default agendaService;
